/* You can add global styles to this file, and also import other style files */


body {
  margin: 0 5px 5px 5px;
}

body, body .ui-widget {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

* {
  -webkit-box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0 0 5px;
  color: #1b3a6b;

}

body a {
  color: #1b3a6b;
  text-decoration: none;
}
.left-panel {
  max-width: 350px;
}

.p-color-dark {
  background-color: #1b3a6b;
  color: #fff;
}

@media (min-width: 576px) {
  .p-col-xss-hidden {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .p-col-sm-hidden {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .p-col-xs-hidden {
    display: none !important;
  }

  .content-container {
    margin-left: 70px;
  }

  .layout-sidebar {
    width: 240px;
    height: 100%;
    position: fixed;
    top: 80px;
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    z-index: 999;
    transform: translate3d(-180px, 0px, 0px);
    -moz-transition: transform 0.15s;
    -o-transition: transform 0.15s;
    -webkit-transition: transform 0.15s;
    transition: transform 0.15s;
    -webkit-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
    -moz-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
    box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
  }

}
